const professionalFieldMixin = () => ({
  data: () => ({
    multiselect: true,
    professionals: [],
  }),
  methods: {
    fillProfessionalData(invoicingTissSettingProfessional) {
      const {
        cbo,
        state,
        council,
        grade,
        operator_code: operatorCode,
        professional_number: professionalNumber,
        professional_id: professionalId
      } = invoicingTissSettingProfessional?.tiss_professional_setting

      this.form.professional_council_registration = professionalNumber
      this.form.professional_id = professionalId

      if (this.multiselect) {
        this.form.professional_council = this.professionalCouncils.find(
          option => option.value === council
        )
        this.form.uf = this.statesUf.find(option => option.value === state)
        this.form.cbo_code = this.cbos.find(option => option.value === cbo)
      } else {
        this.form.contractor_code = operatorCode
        this.form.cbo_code = cbo
        this.form.uf = state
        this.form.professional_council = council
        this.form.degree_of_participation = grade
      }

      this.reloadValues++
    }
  },
  watch: {
    professionals: {
      handler(newValue) {
        if (!newValue.length || this.tissGuide?.id) return
        const professional = newValue.find(
          professional => professional.professional_id === this.tissGuide?.professional_id_fill
        )
        if (!professional) return
        const professionalSettings = professional?.professional
        this.$set(this.form, 'professional_id', professional?.id)
        this.fillProfessionalData(professionalSettings)
      },
      immediate: true
    },
  }
})

export default professionalFieldMixin
